import React from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import Icon from "../ui/Icon"
import { useCheckoutState } from "../../checkout/state"
import { calcNumItemsInCart } from "../../checkout/shared"

const Count = styled.span`
  margin-left: 4px;
`
const CartBadge = styled.span`
  background-color: var(--color-teal);
  color: var(--color-white);
  padding: 2px 8px;
  border-radius: 15px;
  margin-left: 2px;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-width: 36px;
  display: flex;
  justify-content: center;
`
const CartPreview = () => {
  const { cartItems } = useCheckoutState()

  return (
    <>
      {calcNumItemsInCart(cartItems) > 0 ? (
        <Container>
          <CartBadge>
            <Icon iconName="shopping-cart" iconType="Solid" iconColor="white" />{" "}
            <Count id={"shopping-cart-count"}>
              {calcNumItemsInCart(cartItems)}
            </Count>
          </CartBadge>
        </Container>
      ) : (
        ""
      )}
    </>
  )
}

export default CartPreview
